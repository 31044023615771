html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

html {
  scroll-behavior: smooth;
}
.repeatbutton {
  all: unset;
  color: blue;
  -webkit-text-fill-color: blue;
  cursor: pointer;
}

.repeatbutton:focus {
  outline: orange 5px auto;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media(max-width: 1080px){
  html{
      font-size: 93.75%;
  }
}

@media(max-width: 720px){
  html{
      font-size: 87.5%;
  }
}