.wrapper {
  position:relative;
  width:100%;
  height:100%;
  min-height:320px
}
.divimage {  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-repeat:no-repeat;
  background-size:100% auto;
}
.divtext1 {
  position:absolute;
  top:0;
  right:46%;
  width:40%;
  padding-top:4%;  
  text-align:center;
  font-weight:bold;
  color:white;
  font-size:2vw;
  font-family:arial;
}

.divtext2 {
  position:absolute;
  top:0;
  left:57%;
  width:30%;
  padding-top:4%;  
  text-align:left;
  font-weight:bold;
  font-size:1.8vw;
  color:white;
  font-family:arial;
}
@media (min-width: 1200px) {
  .divtext{
    font-size:60px;
  }
}