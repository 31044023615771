.sejafranqueado{
    background-image: linear-gradient(to right, white 19%, #6fc9de 10%, #6fc9de 80%, #6fc9de 80%, white 10% );
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: center;
    padding-top: 6rem;
    padding-bottom: 2rem;
}

@media (max-width: 762px){
    .sejafranqueado{
        margin-top: -1rem;
        align-items: center;
        text-align: center;
        background-image: linear-gradient(to right, white 5%, #6fc9de 5%, #6fc9de 80%, #6fc9de 95%, white 5%);
        padding-bottom: 3rem;
    }
}