.texto {
    position: absolute;
    color:#fff;
    text-align: center;
    top: 50%;
    width: 300px;
    line-height: 80px;
    height: 20px;
    right: 15%;
    font-weight: bold;
    font-size: 4rem;
}

@media (max-width: 1000px){
    .texto{
        font-size: 3rem;
        line-height: 40px;
        top: 60%;
        right: 10%;
    }
}

@media (max-width: 762px){
    .texto{
        font-size: 1.3rem;
        line-height: 40px;
        width: 200px;
        top: 50%;
        right: 0%;
    }
}