.text-center{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%; 
    height: 100px;
    bottom: 100px;
}

.tenha-seu-propio-posto-img{
    display: flex;
    text-align: right;
    justify-content: right;
}

.tenha-seu-propio-posto-row{
    display: flex;
    margin-top: 1rem;
}

.img{
    max-height: 400px;
}

.grid-container{
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
}

.grid-item {
    padding: 20px;
}
.grid-orc{
    grid-column: 1/-1;
}

.grid-item h3{
    color: #FEAF08;
    font-size: 1.5rem;
}

.grid-item p{
    font-size: 0.8rem;
}

.pd1{
    padding-top: 1rem;
}

.product-name{
    top: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 1;
    max-width: 273px;
}

.product-name p {
    color: #FEAF08;
    font-weight: bold;
    font-size: 2rem;
}

.btn-ficha-tecnica{
    border: none;
    background-color: #00365A;
    color: #fff;
    padding: 0.6rem 3rem 0.6rem 3rem;
    border-radius: 0.5rem;
}

.orcamento-produtos{
    min-height: 180px;
    background-color: #ffd904;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

@media (max-width: 1300px){
    .img{
        min-height: 400px;
        padding-top: 3rem;
    }

  
}

@media (max-width: 1300px){
    .img{
        min-height: 400px;
        padding-top: 5rem;
    }
}

@media (max-width: 890px){
    .img{
        min-height: 400px;
        padding-top: 6rem;
    }

    .text-center{
        height: 130px;
        bottom: 100px;
    }
}

@media (max-width: 762px){
    .grid-container{
        display: grid;
        grid-template-columns: auto;
        padding: 10px;
    }

    .img{
        min-height: 400px;
        padding-top: 10rem;
    }

    .text-center{
        height: 200px;
        bottom: 100px;
    }

    .texto-orcamento{
        font-size: 1rem;
    }

    
    .tenha-seu-propio-posto-img{
        display: flex;
        text-align: center;
        justify-content: center;
        margin-bottom: 1rem;
    }

    .tenha-seu-propio-posto-row{
        display: flex;
        text-align: center;
        justify-content: center;
        margin-top: -5rem;
    }

}

@media (max-width: 500px){
 
    .orcamento-produtos{
        min-height: 130px;
        background-color: #ffd904;
        display: grid;
        justify-content: center;
    }
}