.scol1{
    min-height: 57.3vh;
    background-color: '#095090';
    width: 100%;
}
.scol2{
    min-height: 57.3vh;
    background-image: linear-gradient(to top, #040404 60%,#095090 40% );
    width: '100%';
}
.scol1b{
    min-height: 40vh;
    background-color: '#095090';
    width: 100%;
}
.scol2b{
    min-height: 40vh;
    background-image: linear-gradient(to top, #040404 60%,#040404 40% );
    width: '100%';
}
.scontb{
    position: relative; 
    height: 40vh;
    background-image: linear-gradient(to right, #095090 60%,#040404 40% );
}
.simg{
    position: absolute;
    width: 60vw;
    z-index: 4;
    margin-top: 69.5vh;
}
.srow{
    max-width: 99.8vw;
}
.scont{
    position: relative; 
    height: 57.3vh;
}
.divider{
    height: 20vh;
}
.mob{
    background-color: #040404;
    display: none;
    padding: 2vh;
}
@media (max-width: 1000px) {     
    .scont{
        height: auto;
    }
    .divider{
        height:10vh;
    }
 }

 @media (max-width: 872px) { 
    .scont{
        height: auto;
    }
}
@media (max-width: 792px) { 
    .scont{
        height: auto;
    }
}

@media (max-width: 712px) { 
    .scont{
        height: auto;
    }
}
@media (max-width: 632px) { 
    .scont{
        height: auto;
    }
}
@media (max-width: 554px) { 
    .scont{
        height: auto;
    }
}
@media (max-width: 474px) { 
    .scont{
        height: auto;
    }
}
@media (max-width: 395px) { 
    .scont{
        height: auto;
    }
}
@media (max-width: 316px) { 
    .scont{
        height: auto;
    }
}
/* ----------------------------------------------------------------------- */

.banner{
    background-size: 100%;
    background-repeat: no-repeat;
}

.combus-verde{
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1.5rem;
    align-items: center;
}

.bg-fundo{
    background-color: #095090;
    text-align: center;
}

.comb-verde{
    padding-top: 3.2rem;
}


@media (max-width: 1600px){
    .banner{
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: -10rem;
    }
    .combus-verde{
        bottom: 10rem;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 1.5rem;
        align-items: center;
    }

}

@media (max-width: 1400px){
    .banner{
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: -13rem;
    }
    .combus-verde{
        bottom: 10rem;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 1.5rem;
        align-items: center;
    }

    .img-caminhao{
        padding-top: 5rem;
        max-width: 1000px;
    }
}

@media (max-width: 1200px){
    .banner{
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: -14rem;
    }

    .simg{
        margin-top: 80vh;
        width: 60%;
    }

    .scontb{
        display: none;
    }
    .scont{
        display: none;
    }
    .mob{
        display: block;
    }
    .comb-verde{
        padding-top: 4rem;
    }
}

/* @media (max-width: 999px){
    .banner{
        margin-top: -10rem;
    }
} */


@media (max-width: 990px){

    .combus-verde{
        bottom: 7rem;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 1.1rem;
        align-items: center;
    }

    .img-caminhao{
        padding-top: 6rem;
        max-width: 600px;
    }

    .comb-verde{
        padding-top: 1.5rem;
    }
    
}

@media (max-width: 700px){

    .banner{
        margin-top: -19rem;
    }

    .combus-verde{
        bottom: 5rem;
        display: flex;
        justify-content: center;
        text-align: right;
        font-size: 0.8rem;
        padding-left: 0;
        align-items: center;
    }

    .comb-verde{
        padding-top: 1.5rem;
    }

    .img-caminhao{
        padding-top: 23rem;
        max-width: 400px;
    }
    
}

@media (max-width: 499px){

    .banner{
        margin-top: -20rem;
    }
   
    .title-card{
        color: #e0e1e4;
        font-weight: bold;
        padding-top: 1.5rem;
        font-size: 1.7rem;
    }

    .combus-verde{
        bottom: 3.7rem;
        display: flex;
        justify-content: center;
        text-align: right;
        font-size: 0.8rem;
        align-items: center;
    }
}
@media (max-width: 328px){
    .comb-verde{
        padding-top: 22vh;
    }
    .divider{
        padding-top: 20vh;
    }
}