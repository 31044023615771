.galeria{
  margin-top: 4rem;
  display: flex;
  text-align: center;
  justify-content: center;
  padding-bottom: 4rem;
}

  
  /* Carousel base class */
  .carousel {
    margin-bottom: 4rem;
  }
  /* Since positioning the image, we need to help out the caption */
 
  
  /* Declare heights because of positioning of img element */
  .carousel-item {
    height: 32rem;
  }
  .carousel-item > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
  }

  .footer-mob{
    padding-top: 0rem;
  }

  .marketing .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  .marketing h2 {
    font-weight: 400;
  }
  .marketing .col-lg-4 p {
    margin-right: .75rem;
    margin-left: .75rem;
  }
  
  
  .featurette-divider {
    margin: 5rem 0; 
  }
  
  .featurette-heading {
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.05rem;
  }
  
  .colorfrotapropria{
    background-image: linear-gradient(to right, #94c925 48%, white 25%);
    margin-top: -10rem;
    padding-bottom: 20rem;
  }

  
  .logoestruturaimpar{
    display: flex;
    justify-content: right;
    text-align: right;
  }

  .mapa {
    margin-top: 1rem;
  }

  .cards-valores {
    min-height: 180px;
    max-height: 180px;
    min-width: 160px;
  }

  .valores-mob {
    display: none;
  }

  .valores-full {
    display: block;
  }

  .valores:hover{
    background-color: #94C925;
    transition: 0.5s;
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    .text-item{
      font-size: 12px;
      transition: 0.5s;
    }
  }

  .text-item {
    color: #084091;
    font-size: 11px;
    font-weight: bold;
  }
 
  
  /* RESPONSIVE CSS
  -------------------------------------------------- */
  
  @media (min-width: 40em) {
    .carousel-caption p {
      margin-bottom: 1.25rem;
      font-size: 1.25rem;
      line-height: 1.4;
    }
  
    .featurette-heading {
      font-size: 50px;
    }

  }
  
  @media (min-width: 62em) {
    .featurette-heading {
      margin-top: 7rem;
    }
  }

  @media (max-width: 990px){
    .valores-mob {
      display: block;
    }
  
    .valores-full {
      display: none;
    }
  }

  @media (max-width: 762px){
    .imgsobre{
      max-width: 300px;
      padding-bottom: 3rem;
    }

    .carousel-item {
      height: 15rem;
    }

    .mobsobre{
      padding-top: 2rem;
    }

    .colorfrotapropria{
      background-image: linear-gradient(to right, #94c925 100%, white 25%);
      margin-top: -15rem;
      padding-bottom: 20rem;
    }

    .cardgarantimos{
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-left: 2rem;
    }

    .logoestruturaimpar{
      display: flex;
      justify-content: right;
      text-align: center;
    }

    .mapa {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
    }

    .cards-valores {
      min-height: none;
      max-height: none;
      min-width: none;
      max-width: 180px;
      margin-bottom: 0.5rem;
    }
    
  }
@media (max-width: 550px){
    .footer-mob-about{
      margin-top: -20rem;
    }
}