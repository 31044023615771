.fundo-sobre{
    background-image: linear-gradient(to right, #084091 90%, #fff 10%);
    height: 40rem;
}

.fundo-complience{
    background-image: linear-gradient(to bottom, #fff 50%, #040404 50%);
    height: 36rem;
}
.card-sobre{
    top: 30%;
    left: 14%;
}

.cartrr{
    display: flex;
    justify-content: right;
    text-align: right;
}

.cardnews{
    margin-top: -6rem;
}

.news{
    top: 65%;
    padding-top: 8rem;
    margin: 0;
}

.btn-saibamais-sobre{
    padding: 0.6rem 4rem 0.6rem 4rem;
}

.text-follow{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #d8d511;
    padding-right: 1rem;
}

.text-news{
    display: flex;
    text-align: left;
    justify-content: left;
    align-items: center;
    color: #fff;
    font-weight: bold;
}

.wrapper-news{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-bottom: 3rem;
}

.img-news{
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin: auto;
    width: 300px;
}

.complience{
    padding-top: 5rem;
    padding-bottom: 4rem;
    font-size: 2rem;
    color: #43A307;
}

.width-card{
    width: 45rem;
    padding: 2rem;
    text-align: justify;
}

.footer-mob{
    padding: 0rem;
}

@media (max-width: 1000px){
    .img-news{
        width: 200px;
    }

    .width-card{
        width: 35rem;
    }
   
}

@media (max-width: 900px){

    .img-news{
        margin: auto;
    }

    .text-follow{
        padding-left: 1rem;
    }

    .text-news{
        padding-right: 1.5rem;
    }

    .wrapper-news{
        background-color: #084091;
        width: 100%;
        margin: 0;
        padding-top: 2rem;
    }

    .complience{
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

}

@media (max-width: 762px){
    .card-sobre{
        top: 25%;
        left: 10%;
    }

    .news{
        top: 85%;
    }

    .text-follow{
        padding-left: 1rem;
    }

    .text-news{
        padding-right: 1.5rem;
    }

    .wrapper-news{
        background-color: #084091;
        width: 100%;
        margin: 0;
        padding-top: 2rem;
    }

    .cartrr{
        display: block;
        justify-content: center;
        text-align: center;
    }

    .cardnews{
        margin-top: -1rem;
    }

}

@media (max-width: 550px){
    .card-sobre{
        top: 25%;
        left: 3%;
    }

    .news{
        top: 95%;
        margin-top: 7rem;
    }
    
    .text-follow{
        padding-left: 1rem;
    }

    .text-news{
        padding-right: 1.5rem;
    }

    .wrapper-news{
        background-color: #084091;
        width: 100%;
        margin: 0;
        padding-top: 2rem;
    }

    .width-card{
        width: 23rem;
    }

    .cartrr{
        display: block;
        justify-content: center;
        text-align: center;
    }

    .cardnews{
        margin-top: -1rem;
    }

    .footer-mob{
        margin-top: 25rem;
    }
   
}







