.wrapperoe {
    position:relative;
    width:100%;
    height:100%;
    min-height:200px;
    margin-top: 3rem;
  }
  .divimageoe {  position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-repeat:no-repeat;
    background-size:100% auto;
  }
  .divtext {
    position:absolute;
    top:0;
    width:100%;
    padding-top:4%;  
    text-align:center;
    font-weight:bold;
    color:white;
    font-size:2vw;
    font-family:arial;
  }
  .leaflet-container {
    width: 100%;
    height: 40vh;
  }
  
  @media (min-width: 1200px) {
    .divtext{
      font-size:40px;
    }
  }

  
  @media (max-width: 1000px) {
    .wrapperoe {
      margin-top: 1.6rem;
    }
  }

  @media (max-width: 900px) {
    .wrapperoe {
      margin-top: 1.6rem;
    }
  }

  @media (max-width: 762px) {
    .wrapperoe {
      margin-top: 1.6rem;
    }
  }