a {
    position: relative;
    color: #fff;
    text-decoration: none;  
  }
  
 /*  a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    color: #fff;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  } */
  
  a:hover:before {
    color: #fff;
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .product-info{
    max-width: 17rem;
  }

  .pdtop{
    padding-top: 13rem;
  }

  .card-orcamento{
    background-color: #ffd904;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 1rem;
    height: 10rem;
  }
  
  .texto-orcamento{
    padding-left: 5rem;
    color: #084091;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .button-orcamento{
    margin-left: 3rem;
    background-color: #084091;
    padding: 0.6rem 6rem 0.6rem 6rem;
    font-size: 1.2rem;
  }

.tenha-seu-posto-col-title{
    display: flex;
    align-items: center;
    justify-content: right;
}


  @media (max-width: 1600px) {
    .pdtop{
      padding-top: 11rem;
    }
  }

  @media (max-width: 1500px) {
    .pdtop{
      padding-top: 10rem;
    }
  }

  @media (max-width: 1300px) {
    .pdtop{
      padding-top: 8rem;
    }
  }

  @media (max-width: 990px) {
    .product-info{
      width: 30rem;
    }

    .pdtop{
      padding-top: 4rem;
    }

    .card-orcamento{
      align-items: center;
      justify-content: center;
      margin-left: 0;
      
    }
    
    .texto-orcamento{
      padding-left: 2rem;
      color: #084091;
      font-size: 1.5rem;
    }
  
    .button-orcamento{
      background-color: #084091;
      padding: 0.6rem 4rem 0.6rem 4rem;
      font-size: 1rem;
    }
  }

  @media (max-width: 762px) {
    .product-info{
      min-width: 100%;
    }

    .pdtop{
      padding-top:0rem;
    }

    .texto-orcamento{
      padding-left: 2rem;
      color: #084091;
      font-size: 1.4rem;
    }
  
    .button-orcamento{
      background-color: #084091;
      padding: 0.4rem 2rem 0.4rem 2rem;
      font-size: 1rem;
    }

    .tenha-seu-posto-col-title{
      display: flex;
      align-items: center;
      justify-content: left;
    }

  }