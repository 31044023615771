.btn-card-blog{

}

.btn-card-blog:hover{
    background-color: #084091;
}

.card-title{
    font-size: 1.2rem;
    font-weight: 500;
    text-align: justify;
    margin-bottom: 1rem;
}

.title-new-blog{
    font-weight: bold;
    font-size: 1em;
}

.content-new-blog{
    margin-top: 1rem;
}