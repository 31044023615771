.wrapperfc {
    position:relative;
    width:100%;
    height:100%;
    min-height:200px;
    margin-top: 3rem;
  }
  .divimagefc {  position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-repeat:no-repeat;
    background-size:100% auto;
  }
  .divtextfc {
    position:absolute;
    top:0;
    width:100%;
    padding-top:4%;  
    text-align:center;
    font-weight:bold;
    color:white;
    font-size:2vw;
    font-family:arial;
  }

  .formfc {
    margin-top: 20px;
    font-size: 0.8rem;
    padding-right: 4rem;
  }
  
  @media (min-width: 1200px) {
    .divtextfc{
      font-size:40px;
    }
  }

  @media (max-width: 1000px){
    .wrapperfc {
      min-height:150px;
      margin-top: 2rem;
    }
  }

  @media (max-width: 762px){
    .wrapperfc {
      min-height:100px;
    }

    .formfc {
      padding-right: 0rem;
    }
    
  }

