::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #fff !important;
	opacity: 1; /* Firefox */
    font-weight: bold;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #fff !important;
    font-weight: bold;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
	color: #fff !important;
    font-weight: bold;
  }

.menu-mob{
    display: flex;
    text-align: left;
    justify-content: left;
}

@media (max-width: 700px){
    .text-footer-mob{
        padding-top: 3rem;
        text-align: center;
    }

    .menu-mob{
        justify-content: center;
    }
}