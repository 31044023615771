.logo{
    margin-left: 3rem;
}
.logoimg{
    width: 200px;
}

.menu{
    margin-left: 4rem;
}

.links{
    margin-top: 1rem;
    margin-left: 0.5rem;
}

.mobile{
    display: none;
}

.btn-header{
    display: block;
}

.brand{
    margin-left: 2rem;
    margin-bottom: 0.1rem;
}

.container-menu{
    margin-left: 5rem;
}

.hr-menu{
    background-color: #1470D2;
    width: 100%;
    margin-left: 0;
    margin-top: 1rem;
}

.separator{
   height: 15px;
   width: 1px;
   margin: 12px 5px;
   overflow: hidden;
   background-color: #1470D2;
   border-right: 2px solid #FFF;
}

.link-hover:hover{
    background-color: #1470D2;
    border-radius: 0.5rem;
}

#basic-nav-dropdown{
    color: #FFF;
}

@media (max-width: 1200px) {
	.logo{
        margin-left: 1rem;
    }
    .menu{
        margin-left: 2rem;
    }

    .container-menu{
        margin-left: 3rem;
        margin-top: 1rem;
    }

    .brand{
        margin-left: 0;
        margin-bottom: 0;
    }

    .hr-menu{
        width: 95%;
    }
}

@media (max-width: 990px) {
    .separator{
        height: 0px;
     }
    .hr-menu{
        display: none;
    }
    .logoimg{
        max-width: 50%;
    }
    .btn-header{
        display: none;
    }
}

@media (max-width: 900px) {
	.pesquisa{
        display: none;
    }

    .mobile{
        display: block;
    }
    .hr-menu{
        width: 100%;
    }

    .separator{
        height: 0px;
     }

    .btn-header{
        display: none;
    }
}



@media (max-width:  762px){
  
}

